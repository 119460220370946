
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$controls-height: calc(100vh - 80px);

.control-list {
  $parent: &;
  position: relative;
  background-color: $color-white;
  border-radius: $base-border-radius * 2;
  height: $controls-height;

  &--is-sticky &__inner {
    position: fixed;
    top: 0;
  }
  &__inner {
    background-color: $color-white;
    border-radius: $base-border-radius * 2;
    display: flex;
    flex-direction: column;
    max-height: $controls-height;
    width: $controls-width;
  }
  &__title {
    width: 100%;
    padding: 12px 20px 10px;
    border-bottom: 1px solid $color-black-op-10;
    border-radius: $base-border-radius * 2 $base-border-radius * 2 0 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    flex-shrink: 0;
  }
  &__body {
    padding: 12px 20px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__group {
    padding: 0;
    border: none;
    background-color: $color-white;
    margin: 10px 0 0 0;
    &:first-child {
      margin: 0;
    }
    ::v-deep .esmp-collapser__body {
      padding-top: 12px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:not(:last-child) {
      margin-bottom: 8px;
    }
    &-icon {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-grayscale-05;
      border-radius: $base-border-radius;
      flex-shrink: 0;
      margin-right: 12px;
      opacity: 0.8;
    }
    &-name {
      font-size: 14px;
    }
  }
}
