
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$controls-offset: 40px;

.service-form {
  width: 100%;
  display: flex;
  height: 100%;
  align-items: stretch;
  &__elements {
    display: flex;
    width: calc(100% - #{$controls-width} - #{$controls-offset});
  }
  &__controls {
    width: $controls-width;
    margin-left: $controls-offset;
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 50px;
  width: 100%;
  > span {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 50px;
  }
  & + & {
    margin-top: 20px;
  }
  &--no-drag ::v-deep .control {
    cursor: default;
  }
  &__title {
    margin-bottom: math.div($controls-offset, 2);
  }
}
