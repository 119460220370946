
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.control {
  background-color: $color-white;
  margin: 20px 0 0 0;
  cursor: move;
  border-radius: $base-border-radius;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
  &--is-dark {
    background-color: $color-grayscale-05;
  }
  &:first-child {
    margin: 0;
  }
  &__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    border-radius: $base-border-radius $base-border-radius 0 0;
  }
  &__body {
    border-radius: 8px;
    box-shadow: 0 0 1px rgba(28, 41, 61, 0.1), 0 -36px 36px rgba(28, 41, 61, 0.04);
  }
  &-content {
    padding: 10px;
  }
  &__tech-name {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid rgba(16, 24, 40, 0.1);
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #101828;
    span {
      margin-left: 4px;
    }
    &--is-new {
      background-color: $color-yellow-status-op-15;
    }
    &--is-changed {
      background-color: $color-secondary-2-day-op-15;
    }
  }
  &__name {
    margin: 0 0 0 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #101828;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__actions {
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__action {
    transition: transform $base-animation;
    &:hover {
      cursor: pointer;
    }
    & + & {
      margin-left: 4px;
    }
    &--disabled {
      color: $color-grayscale-60;
      pointer-events: none;
      &:hover {
        cursor: default;
      }
    }
    &--rotated {
      transform: rotate(-180deg);
    }
  }
  ::v-deep .esmp-collapser {
    border: none;
    padding: 0;
    background-color: transparent;
    &__heading {
      display: none;
    }
    &__body {
      padding: 0;
    }
  }
}
.collapser-element {
  padding: 10px 20px;
}
.collapser-element.table-view,.collapser-element.group-view {
  padding: 0;
}
